import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Empty,
  Popover,
  Row,
  Tag,
  Tooltip
} from 'antd';
import clsx from 'clsx';
import {
  drop,
  forEach,
  groupBy,
  includes,
  isEmpty,
  keys,
  map,
  nth,
  omit,
  slice
} from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  CloseIcon,
  InfoIconDark,
  Timer,
  Timezone,
  TrophyInHand
} from '../../../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  DATE_TIME_WITH_AT,
  DEFAULT_PAGE_SIZE,
  EQC_FILTER,
  INS_STATUS_KEYS,
  ROUTES,
  STAGE_STATUS,
  STAGE_STATUS_KEYS,
  STAGE_STATUS_LABEL,
  STAGE_STATUS_TEXT_CLASS
} from '../../../../common/constants';
import { removeHistoryStateData, titleCase } from '../../../../common/utils';
import CollapsibleFilterWrapper from '../../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../../components/CommonCard';
import CommonSelect from '../../../../components/CommonSelect';
import CommonTable from '../../../../components/CommonTable';
import EllipsisText from '../../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../../components/InfiniteScrollHandler';
import Portal from '../../../../components/Portal';
import SearchComponent from '../../../../components/SearchComponent';
import {
  AGENCY_EQC_LIST,
  GET_AGENCY_PENDING_EQC_APPROVAL_LOG_COUNT
} from '../../graphql/Queries';
import Filters from '../Filters';
import ThankyouModal from './ThankyouModal';

const { Option } = CommonSelect;
const TodoList = () => {
  const history = useHistory();
  const location = useLocation();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const tenantRecord = history?.location?.state?.tenantRecord || [];
  const projectRecord = history?.location?.state?.projectRecord || [];
  const initialProjectFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'updatedAt', order: 'DESC' },
    isRfi: false
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const [paginationProp, setPaginationProp] = useState(
    navPagination || initialPaginationValue
  );
  const [projectFilter, setProjectFilter] = useState(
    navFilter || initialProjectFilter
  );
  const [open, setOpen] = useState(false);
  const [selectedApproverRecord, setSelectedApproverRecord] = useState();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [instructionListData, setInstructionListData] = useState([]);
  const [tenantId, setTenantId] = useState(projectFilter?.tenantId);
  const [isOverdue, setIsOverdue] = useState(projectFilter?.isOverdue);
  const [projectId, setProjectId] = useState(projectFilter?.projectId);
  const [isRfi, setIsRfi] = useState(projectFilter?.isRfi);
  const [showModal, setShowModal] = useState(false);
  const [selectedTenantRecord, setSelectedTenantRecord] = useState(
    tenantRecord
  );
  const [selectedProjectRecord, setSelectedProjectRecord] = useState(
    projectRecord
  );
  const [eqcStageStatus, setEqcStageStatus] = useState(
    navFilter
      ? navFilter?.eqcStageStatus
      : // eslint-disable-next-line no-undef
        localStorage.getItem(EQC_FILTER) || undefined
  );
  const approversByLevel = groupBy(
    selectedApproverRecord?.eqcStageHistories?.[0]
      ?.projectEqcTypeStageApprovals,
    function (b) {
      return b?.levelNumber;
    }
  );
  const levelsToShowAllApprovers = drop(
    Object.keys(approversByLevel),
    includes(
      [
        STAGE_STATUS_KEYS?.APPROVAL_PENDING,
        STAGE_STATUS_KEYS?.FAIL,
        STAGE_STATUS_KEYS.ACCEPTED,
        STAGE_STATUS_KEYS.REJECTED
      ],
      selectedApproverRecord?.eqcStageHistories?.[0]?.status
    )
      ? selectedApproverRecord?.eqcStageHistories?.[0]?.levelNumber - 1
      : selectedApproverRecord?.eqcStageHistories?.[0]?.levelNumber
  );

  useEffect(() => {
    if (navFilter) {
      setProjectFilter(navFilter);
    }
    if (navFilter?.eqcStageStatus) {
      setEqcStageStatus(navFilter?.eqcStageStatus);
    }
    if (navFilter?.isOverdue) {
      setIsOverdue(navFilter?.isOverdue);
    }
    if (navFilter?.projectId) {
      setProjectId(navFilter?.projectId);
    }
    if (navFilter?.isRfi) {
      setIsRfi(navFilter?.isRfi);
    }
    if (navFilter?.tenantId) {
      setTenantId(navFilter?.tenantId);
    }
    if (navPagination) {
      setPaginationProp(navPagination);
    }
  }, [navFilter, navPagination]);

  const { data: approvalCountData, loading: countLoading } = useQuery(
    GET_AGENCY_PENDING_EQC_APPROVAL_LOG_COUNT,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [fetchInstructionList, { loading: instructionLoading }] = useLazyQuery(
    AGENCY_EQC_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.agencyEqcList?.data || [];
        const pagination = {
          ...paginationProp,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          total: res?.agencyEqcList?.total
        };
        if (scrollFlag) {
          const datalist = [...instructionListData, ...data];
          setInstructionListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setInstructionListData(datalist);
        }
        setHasMore(!!data?.length);
        setLoading(false);
        setPaginationProp(pagination);
      },
      onError() {
        if (isDesktopViewport) {
          setInstructionListData([]);
        }
        setLoading(false);
      }
    }
  );

  useEffect(() => {
    if (history?.location?.state?.showThankyouModal) {
      setShowModal(true);
    }
  }, [history?.location?.state?.showThankyouModal]);

  useEffect(() => {
    fetchInstructionList({
      variables: { filter: { ...projectFilter } }
    });
    if (history?.location?.state) {
      // eslint-disable-next-line no-undef
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectFilter]);

  useEffect(() => {
    let newFilter = {
      ...projectFilter,
      eqcStageStatus,
      projectId,
      tenantId,
      skip: projectFilter?.skip !== 0 ? projectFilter?.skip : 0
    };
    if (isOverdue) {
      newFilter.isOverdue = isOverdue;
    } else {
      newFilter = omit(newFilter, 'isOverdue');
    }
    setPaginationProp({
      ...paginationProp,
      current: paginationProp?.current !== 1 ? paginationProp?.current : 1
    });
    setProjectFilter(newFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, tenantId, eqcStageStatus, isOverdue]);

  const getStageNameAndStatus = (record) => {
    const { eqcStageHistories } = record;
    if (!eqcStageHistories || !eqcStageHistories.length) {
      return '-';
    }
    const { name, status } = nth(eqcStageHistories, 0);
    return (
      <div>
        <span>
          <EllipsisText
            text={
              `${name} (${record?.eqcStageIndex}/${record?.eqcStageCount})` ||
              '-'
            }
          />
        </span>
        {includes(
          [
            STAGE_STATUS?.PASS,
            STAGE_STATUS?.FAIL,
            STAGE_STATUS?.SKIP,
            STAGE_STATUS?.BYPASS
          ],
          status
        ) && (
          <span
            className={`text-no-break d-inline-flex ${STAGE_STATUS_TEXT_CLASS[status]}`}
          >
            ({titleCase(STAGE_STATUS[status])})
          </span>
        )}
      </div>
    );
  };
  const ChangeStageStatus = (status) => {
    switch (status) {
      case STAGE_STATUS.PASS:
        return <Tag className="active-tag">{titleCase(STAGE_STATUS.PASS)}</Tag>;
      case STAGE_STATUS.APPROVED:
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.APPROVED)}</Tag>
        );
      case STAGE_STATUS.ACCEPTED:
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.ACCEPTED)}</Tag>
        );
      case STAGE_STATUS.APPROVAL_PENDING:
        return (
          <Tag className="yellow-tag">
            {titleCase(STAGE_STATUS.APPROVAL_PENDING)}
          </Tag>
        );
      case STAGE_STATUS.SKIP:
        return <Tag className="yellow-tag">{titleCase(STAGE_STATUS.SKIP)}</Tag>;
      case STAGE_STATUS.REDO:
        return <Tag color="red">{titleCase(STAGE_STATUS.REDO)}</Tag>;
      case STAGE_STATUS.REJECTED:
        return <Tag color="red">{titleCase(STAGE_STATUS.REJECTED)}</Tag>;
      case STAGE_STATUS.BYPASS:
        return (
          <Tag className="inactive-tag">{titleCase(STAGE_STATUS.BYPASS)}</Tag>
        );

      default:
        return <Tag color="red">{titleCase(STAGE_STATUS.FAIL)}</Tag>;
    }
  };

  const getLevelWiseApproversFromPreviousHistory = () => {
    return map(
      selectedApproverRecord?.eqcStageHistories?.[0]?.previousEqcApprovalLogs,
      (record, index) => {
        return (
          <>
            <Card className="approvers-card">
              <Row justify="space-between" gutter={18}>
                <Col span={14}>
                  <EllipsisText
                    className="text-secondary mb-15 level-name"
                    text={record?.levelName}
                  />
                  {record?.internalApproverName ||
                  record?.externalApproverName ? (
                    <div>
                      By
                      <Tag className="ml-5 approver-name-tag">
                        <EllipsisText
                          text={
                            record?.internalApproverName ||
                            record?.externalApproverName
                          }
                        />
                      </Tag>
                    </div>
                  ) : (
                    '-'
                  )}
                </Col>
                <Col span={10}>
                  <div className="mb-15">
                    {ChangeStageStatus(record?.approvalStatus)}
                  </div>
                  <div className="font-size-12">
                    {moment(record?.approvedAt || record?.redoAt).format(
                      DATE_TIME_WITH_AT
                    )}
                  </div>
                </Col>
              </Row>
            </Card>
            {(selectedApproverRecord?.eqcStageHistories?.[0]
              ?.previousEqcApprovalLogs?.length -
              1 >
              index ||
              levelsToShowAllApprovers?.length > 0) && (
              <Divider className="approver-divider" dashed />
            )}
          </>
        );
      }
    );
  };

  const getAllApproversDataByLevel = (level, index) => {
    const fetchName = [];
    if (approversByLevel?.[level]?.length > 0) {
      forEach(approversByLevel?.[level], (record) => {
        fetchName.push(
          record?.internalApproverName || record?.externalApproverName
        );
      });
    }
    return (
      <>
        <Card className="approvers-card">
          <Row justify="space-between" gutter={18}>
            <Col span={14}>
              <EllipsisText
                className="text-secondary mb-15 level-name"
                text={approversByLevel?.[level]?.[0]?.levelName || '-'}
              />
            </Col>
            <Col span={10}>
              <div className="mb-15">
                {ChangeStageStatus(STAGE_STATUS_KEYS.APPROVAL_PENDING)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                from
                {fetchName?.length > 0 && (
                  <Tag className="ml-5 approver-name-tag">
                    <EllipsisText text={fetchName?.[0]} />
                  </Tag>
                )}
                {fetchName?.length > 1 && (
                  <Tooltip
                    title={slice(fetchName, 1, fetchName?.length).join(', ')}
                    placement="right"
                  >
                    {`and +${fetchName?.length - 1}`}
                  </Tooltip>
                )}
              </div>
            </Col>
          </Row>
        </Card>
        {levelsToShowAllApprovers?.length - 1 > index && (
          <Divider className="approver-divider" dashed />
        )}
      </>
    );
  };
  const getApprovers = (values, record) => {
    const {
      levelName,
      status,
      projectEqcTypeStageApprovals,
      previousEqcApprovalLogs
    } = nth(record?.eqcStageHistories, 0);
    if (
      (isEmpty(projectEqcTypeStageApprovals) &&
        isEmpty(previousEqcApprovalLogs)) ||
      includes([STAGE_STATUS?.SKIP, STAGE_STATUS?.BYPASS], status)
    ) {
      return '-';
    }
    return (
      <div>
        <div className="d-flex align-center">
          {!includes([STAGE_STATUS?.PASS, STAGE_STATUS?.FAIL], status) &&
            levelName}
          <Popover
            overlayClassName="approver-info"
            onOpenChange={setOpen}
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('.todo-list')
            }
            title={
              <Row
                justify="space-between"
                align="top"
                onClick={(e) => e.stopPropagation()}
              >
                <Col span={20}>
                  <EllipsisText text={record?.eqcName} />
                </Col>
                <Col span={2}>
                  <CloseIcon
                    height={24}
                    width={24}
                    className="pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(false);
                    }}
                  />
                </Col>
              </Row>
            }
            trigger="click"
            placement={isDesktopViewport ? 'left' : 'top'}
            destroyTooltipOnHide
            open={open && selectedApproverRecord?.id === record?.id}
            content={
              <div onClick={(e) => e.stopPropagation()}>
                {getLevelWiseApproversFromPreviousHistory()}
                {map(levelsToShowAllApprovers, (level, index) => {
                  return getAllApproversDataByLevel(level, index);
                })}
              </div>
            }
          >
            {!includes([STAGE_STATUS?.BYPASS, STAGE_STATUS?.SKIP], status) && (
              <InfoIconDark
                height={18}
                width={18}
                className="approver-info-icon ml-5"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedApproverRecord(record);
                }}
              />
            )}
          </Popover>
          {includes([STAGE_STATUS?.PASS, STAGE_STATUS?.FAIL], status) && (
            <span className="ml-5">Approval log</span>
          )}
        </div>
        {!includes([STAGE_STATUS?.PASS, STAGE_STATUS?.FAIL], status) && (
          <span
            className={`text-no-break d-inline-flex ${STAGE_STATUS_TEXT_CLASS[status]}`}
          >
            ({titleCase(STAGE_STATUS[status])})
          </span>
        )}
      </div>
    );
  };

  const getNextStage = (record) => {
    if (
      !record?.eqcStageHistories?.[0]?.inspectorReminderDate ||
      record?.eqcStageHistories?.[0]?.status === STAGE_STATUS.PASS ||
      record?.eqcStageHistories?.[0]?.isRfiStage
    ) {
      return '-';
    }
    const isOverDue =
      moment(record?.eqcStageHistories?.[0]?.inspectorReminderDate) < moment();
    return (
      <div className={isOverDue ? 'text-danger' : ''}>
        {isOverDue ? `Due since` : `Due in`}
        {` ${moment(record?.eqcStageHistories?.[0]?.inspectorReminderDate).from(
          moment(),
          true
        )}`}
        <div>
          <div>
            {moment(record?.eqcStageHistory?.[0]?.inspectorReminderDate)
              .tz(record?.project?.timeZone)
              .format(DATETIMEWITHDIVIDE)}
            <div className="d-flex align-center timezone-div">
              <Timezone title="timeZone" />
              {record?.project?.timeZone}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getDueDate = (record) => {
    const isOverDue =
      record?.eqcStageHistories?.[0]?.isRfiStage &&
      (moment(record?.eqcStageHistories?.[0]?.inspectorReminderDate).isBefore(
        moment()
      ) ||
        record?.eqcStageHistories?.[0]?.isOverdue);
    return (
      <div className={`d-flex align-center ${isOverDue ? 'text-danger' : ''}`}>
        {isOverDue && (
          <Tooltip title="Overdue">
            <div className="d-flex align-center">
              <Timer height="18" width="18" />
            </div>
          </Tooltip>
        )}
        <div>
          <EllipsisText
            className={isOverDue ? 'text-danger' : ''}
            text={record?.eqcName || '-'}
          />
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return (
          <div className="d-flex align-center">
            <span>{projectFilter?.skip + index + 1}</span>
            <span className="ml-5 d-flex align-center">
              {record?.status === INS_STATUS_KEYS.NOTICE && (
                <Tooltip title="Notice">
                  <InfoIconDark height="20px" width="20px" />
                </Tooltip>
              )}
            </span>
          </div>
        );
      }
    },
    {
      title: 'PROJECT NAME',
      dataIndex: 'projectName',
      key: 'projectName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'projectName' && sortedInfo?.order,
      render: (text, record) => (
        <div>
          <EllipsisText text={record?.project?.name || '-'} />
        </div>
      )
    },
    {
      title: 'NAME',
      dataIndex: 'eqcName',
      key: 'eqcName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'eqcName' && sortedInfo?.order,
      render: (text, record) => getDueDate(record)
    },
    {
      title: 'TYPE',
      dataIndex: 'projectEqcTypeName',
      key: 'projectEqcTypeName',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'projectEqcTypeName' && sortedInfo?.order,
      render: (text, record) => (
        <div>
          <EllipsisText text={record?.projectEqcTypeName || '-'} />
        </div>
      )
    },
    {
      title: 'STAGE',
      key: 'eqcStageHistory',
      dataIndex: 'eqcStageHistory',
      render: (text, record) => {
        return getStageNameAndStatus(record);
      }
    },
    {
      title: 'APPROVERS',
      key: 'approver',
      dataIndex: 'approver',
      render: (values, record) => getApprovers(values, record)
    },
    {
      title: 'INSPECTED BY',
      key: 'modifiedBy',
      dataIndex: 'modifiedBy',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'modifiedBy' && sortedInfo?.order,
      render: (text, record) => record?.modifier?.name || '-'
    },
    {
      title: 'NEXT STAGE',
      key: 'nextStage',
      render: (_, record) => getNextStage(record)
    },
    {
      title: 'APPROVAL LINK',
      key: 'approvalUrl',
      dataIndex: 'approvalUrl',
      render: (text, record) =>
        record?.eqcStageHistories?.[0]?.eqcApprovalLogs?.[0]?.approvalUrl &&
        record?.eqcStageHistories?.[0]?.status ===
          STAGE_STATUS?.APPROVAL_PENDING ? (
          <a
            href={
              record?.eqcStageHistories?.[0]?.eqcApprovalLogs?.[0]?.approvalUrl
            }
            rel="noreferrer"
            onClick={(event) => event?.stopPropagation()}
          >
            Review
          </a>
        ) : (
          '-'
        )
    }
  ];
  const onSearchChange = async (value) => {
    setPaginationProp(initialPaginationValue);
    setProjectFilter({ ...projectFilter, skip: 0, search: value });
  };

  const handleRefetch = () => {
    fetchInstructionList({
      variables: {
        filter: {
          ...projectFilter,
          skip: instructionListData?.length
        }
      }
    });
  };
  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProjectFilter({
        ...projectFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
    } else {
      setProjectFilter({
        ...projectFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'updatedAt', order: 'DESC' }
      });
    }
  };
  const handleClearFilter = () => {
    setProjectId();
    setIsRfi(false);
    setIsOverdue(false);
    setTenantId();
    setEqcStageStatus();
    setProjectFilter({
      ...projectFilter,
      search: '',
      isRfi: false,
      isOverdue: false
    });
  };
  const EmptyState = !loading && (
    <Empty
      image={TrophyInHand}
      imageStyle={{
        height: 148
      }}
      description={
        <div>
          <div className="empty-text">You are up to date!</div>
          {(tenantId || projectId || eqcStageStatus || projectFilter?.search) &&
          !instructionLoading ? (
            <div className="empty-text-secondary">
              Filter applied, no data found.
              <Button
                type="link"
                className="clear-filter-button"
                onClick={handleClearFilter}
              >
                Clear Filter.
              </Button>
            </div>
          ) : (
            <div className="empty-text-secondary">No data found.</div>
          )}
        </div>
      }
    />
  );

  const handleRowClick = (record) => {
    if (record?.id) {
      history.push(`${ROUTES.EQC}/${record?.id}`, {
        navFilter: projectFilter,
        navPagination: paginationProp,
        tenantRecord: selectedTenantRecord,
        projectRecord: selectedProjectRecord,
        currentPath: history?.location?.pathname
      });
    }
  };

  return (
    <>
      <Portal portalId="add-search-projects">
        <CollapsibleFilterWrapper
          searchProps={{
            id: 'search-container-id ',
            className: `search-component ${
              projectFilter?.search && 'focused-search'
            }`,
            getData: onSearchChange,
            defaultValue: projectFilter?.search,
            name: 'issue'
          }}
          isOpen={!!(tenantId || projectId || eqcStageStatus)}
          className="width-percent-100"
        >
          <Row
            className="d-flex filter-search"
            justify={isDesktopViewport ? 'end' : 'start'}
            align="middle"
            wrap
            gutter={[8, 8]}
          >
            <Col span={isDesktopViewport ? null : 12}>
              <Checkbox
                onChange={(event) => {
                  setIsRfi(event?.target?.checked);
                  setProjectFilter({
                    ...projectFilter,
                    isRfi: event?.target?.checked,
                    skip: 0
                  });
                  setPaginationProp({ ...paginationProp, current: 1 });
                  if (!event?.target?.checked) {
                    removeHistoryStateData(
                      history,
                      location,
                      'projectFilter',
                      'isRfi'
                    );
                  }
                }}
                checked={isRfi}
              >
                RFI
              </Checkbox>
            </Col>
            <Col span={isDesktopViewport ? null : 12}>
              <Checkbox
                onChange={(event) => {
                  setIsOverdue(event?.target?.checked);
                  setProjectFilter({
                    ...projectFilter,
                    skip: 0
                  });
                  setPaginationProp({ ...paginationProp, current: 1 });
                  removeHistoryStateData(
                    history,
                    location,
                    'projectFilter',
                    'isOverdue'
                  );
                }}
                checked={isOverdue}
              >
                RFI Overdue
              </Checkbox>
            </Col>
            <Filters
              tenantFilter
              projectFilter
              tenantId={tenantId}
              setTenantId={(id) => {
                setTenantId(id);
                setProjectFilter({ ...projectFilter, skip: 0 });
                setPaginationProp({ ...paginationProp, current: 1 });
              }}
              setProjectId={(id) => {
                setProjectId(id);
                setProjectFilter({ ...projectFilter, skip: 0 });
                setPaginationProp({ ...paginationProp, current: 1 });
              }}
              projectId={projectId}
              selectedProjectRecord={selectedProjectRecord}
              selectedTenantRecord={selectedTenantRecord}
              setSelectedProjectRecord={setSelectedProjectRecord}
              setSelectedTenantRecord={setSelectedTenantRecord}
            />

            <Col span={!isDesktopViewport && 12}>
              <CommonSelect
                placeholder="Stage Status"
                className={clsx(
                  'instruction-selector ',
                  !isDesktopViewport && 'clear-icon-scale',
                  isDesktopViewport ? 'width-200' : 'width-percent-100',
                  eqcStageStatus?.length > 0 && 'focused-filter'
                )}
                value={eqcStageStatus}
                onChange={(stageStatus) => {
                  // eslint-disable-next-line no-undef
                  localStorage.setItem(EQC_FILTER, [stageStatus]);
                  setEqcStageStatus(stageStatus);
                  setProjectFilter({ ...projectFilter, skip: 0 });
                  setPaginationProp({ ...paginationProp, current: 1 });
                }}
                allowClear
              >
                {map(keys(STAGE_STATUS_LABEL), (status, index) => (
                  <Option key={index} value={status}>
                    {STAGE_STATUS_LABEL[status]}
                  </Option>
                ))}
              </CommonSelect>
            </Col>
            {isDesktopViewport && (
              <Col>
                <SearchComponent
                  id="search-container-id"
                  className={clsx(
                    'search-component',
                    projectFilter?.search && 'focused-search'
                  )}
                  getData={onSearchChange}
                  defaultValue={projectFilter?.search}
                />
              </Col>
            )}
          </Row>
        </CollapsibleFilterWrapper>
      </Portal>
      <div className="pointer todo-list">
        {isDesktopViewport ? (
          <CommonTable
            rowClassName={(record) => {
              if (record?.isActive === false) return 'deactivated-color';
            }}
            columns={columns}
            data={instructionListData}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            loading={loading || instructionLoading}
            rowKey={(obj) => obj?.id}
            locale={{ emptyText: EmptyState }}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record)
              };
            }}
          />
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={instructionListData?.length}
            skeletonRows={columns?.length - 3}
            loading={loading}
            emptyState={EmptyState}
          >
            {instructionListData?.length < 1
              ? EmptyState
              : map(instructionListData, (todo, index) => {
                  return (
                    <CommonCard
                      key={todo?.id}
                      onClick={() => handleRowClick(todo)}
                    >
                      <div className="common-card d-flex">
                        <div className="mr-5 fw-medium">
                          <div className="d-flex">
                            <span className="mr-5 d-flex">
                              {todo?.status === INS_STATUS_KEYS.NOTICE && (
                                <Tooltip title="Notice">
                                  <InfoIconDark height="16px" width="16px" />
                                </Tooltip>
                              )}
                            </span>
                            {index + 1}.
                          </div>
                        </div>
                        <div>
                          <div className="card-header fw-medium">
                            <span className="text-break">
                              {todo?.project?.name}
                            </span>
                          </div>
                          <div className="card-content text-secondary">
                            <br />
                            <div className="d-flex mb-15">
                              <span className="fw-medium mr-5">Name:</span>
                              {getDueDate(todo)}
                            </div>
                            <div className="mb-15">
                              <span className="fw-medium mr-5">Type:</span>
                              {todo?.projectEqcTypeName || '-'}
                            </div>
                            <div className="d-flex mb-15">
                              <span className="fw-medium mr-5">Stage:</span>
                              {getStageNameAndStatus(todo)}
                            </div>
                            <div className="mb-15">
                              <span className="fw-medium mr-5">Approver:</span>
                              {getApprovers(index, todo)}
                            </div>
                            <div className="mb-15">
                              <span className="fw-medium mr-5">
                                Updated by:
                              </span>
                              {todo?.modifier?.name || '-'}
                            </div>
                            <div className="mb-15">
                              <span className="fw-medium mr-5">
                                Next stage:
                              </span>
                              {getNextStage(todo)}
                            </div>
                            <div className="mb-15">
                              <span className="fw-medium mr-5">
                                Approval link:
                              </span>
                              {todo?.eqcStageHistories?.[0]
                                ?.eqcApprovalLogs?.[0]?.approvalUrl ? (
                                <a
                                  href={
                                    todo?.eqcStageHistories?.[0]
                                      ?.eqcApprovalLogs?.[0]?.approvalUrl
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Review
                                </a>
                              ) : (
                                '-'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CommonCard>
                  );
                })}
          </InfiniteScrollHandler>
        )}
      </div>
      {showModal && (
        <ThankyouModal
          setShowModal={setShowModal}
          showModal={showModal}
          loading={countLoading}
          count={approvalCountData?.getAgencyPendingEqcApprovalLogCount?.total}
          handleClick={() => {
            removeHistoryStateData(
              history,
              history?.location,
              'showThankyouModal'
            );
          }}
        />
      )}
    </>
  );
};

export default TodoList;
