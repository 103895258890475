import { Badge, Image, Space, Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { AudioIcon } from '../../../../assets/svg';
import {
  BREAKPOINTS,
  DATE_TIME_WITH_AT,
  GA_EVENT,
  GA_LABEL
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import AudioPlayerModal from '../../../../components/AudioPlayerModal';
import EllipsisText from '../../../../components/EllipsisText';

const InstructionMoreDetails = ({ instructionData }) => {
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  return (
    <div className="instruction-tab-details">
      {!isDesktopViewport && (
        <div className="name-and-time d-flex">
          <Tooltip title={instructionData?.creator?.name}>
            <div className="ellipsis-text">
              {instructionData?.creator?.name}
            </div>
          </Tooltip>
          <Badge status="default" className="ml-5 mr-5" color="#676C77" />
          {moment(instructionData?.createdOn).format(DATE_TIME_WITH_AT)}
        </div>
      )}
      <div>
        <EllipsisText text={instructionData?.description || '-'} />
      </div>
      <div className="d-flex justify-between align-center">
        {instructionData?.descriptionPhoto?.length > 0 && (
          <Space direction="vertical" size={12} className="mt-12">
            <div>
              <Image.PreviewGroup
                preview={{
                  visible:
                    instructionData?.revision === previewImageKey && isVisible,
                  onVisibleChange: (visible) => {
                    setIsVisible(visible);
                    if (visible) {
                      Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                        label: GA_LABEL.OPENED_IMAGE_VIEWER,
                        // eslint-disable-next-line no-undef
                        pathname: window?.location?.href
                      });
                    }
                  }
                }}
              >
                <Space className="image-preview-wrapper issue-images">
                  {React.Children.map(
                    instructionData?.descriptionPhoto,
                    (photo) => (
                      <Image
                        src={photo}
                        alt="logo"
                        height="64px"
                        width="64px"
                        onClick={() =>
                          setPreviewImageKey(instructionData?.revision)
                        }
                      />
                    )
                  )}
                  {instructionData?.descriptionPhoto?.length > 1 && (
                    <div
                      className="extra-count pointer"
                      onClick={() => {
                        setIsVisible(true);
                        setPreviewImageKey(instructionData?.revision);
                      }}
                    >
                      {`+${instructionData?.descriptionPhoto?.length - 1}`}
                    </div>
                  )}
                </Space>
              </Image.PreviewGroup>
            </div>
          </Space>
        )}
        {instructionData?.descriptionAudio?.length > 0 && (
          <Space direction="vertical" size={12}>
            <div>
              <div
                className="audio-player-wrapper issue-audio"
                onClick={() => {
                  setShowAudioModal(true);
                  Event(GA_EVENT.DOWNLOAD_AUDIO, {
                    label: GA_LABEL.DOWNLOAD_AUDIO,
                    // eslint-disable-next-line no-undef
                    pathname: window?.location?.href
                  });
                }}
              >
                <AudioIcon />
                {instructionData?.descriptionAudio?.length > 1 && (
                  <div className="extra-count">{`+${
                    instructionData?.descriptionAudio?.length - 1
                  }`}</div>
                )}
              </div>
            </div>
          </Space>
        )}
      </div>
      {instructionData?.descriptionAudio?.length > 0 && (
        <AudioPlayerModal
          audios={instructionData?.descriptionAudio}
          showModal={showAudioModal}
          setShowModal={setShowAudioModal}
        />
      )}
    </div>
  );
};

export default InstructionMoreDetails;
