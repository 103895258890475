import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_STAGE_HISTORY = gql`
  query getEqcStageHistoryWithoutAuth($id: ID!, $token: String!) {
    getEqcStageHistoryWithoutAuth(id: $id, token: $token) {
      id
      eqcStageId
      name
      status
      levelName
      levelNumber
      eqcStageStartedAt
      byPassRemark
      byPassedImages
      byPassedAudios
      eqcStageCompletedAt
      eqcStageSynceStartedAt
      inspectorReminderDate
      isRfiStage
      tenantId
      approvalPendingCount
      projectId
      project {
        timeZone
      }
      projectAgency {
        agency {
          id
          name
        }
      }
      eqcStage {
        id
        uuid
      }
      eqc {
        id
        eqcName
        eqcStageIndex
        eqcStageCount
        uom
        status
        uuid
        projectEqcTypeId
        projectEqcTypeName
        eqcStages {
          eqcStageSynceStartedAt
          eqcStageSynceCompletedAt
          uuid
        }
      }
      eqcApprovalLogs {
        revision
      }
      action {
        name
      }
      actionAt
      drawingTitleImg
      drawingImg
      drawingPDF
      witnessesImg
      witnessesId
      latitude
      reviewedBy
      updatedAt
      longitude
      previousHistoryData {
        status
        levelName
        levelNumber
        updatedAt
        eqcApprovalLogs {
          approvedAt
          redoAt
          approvalStatus
          internalApproverId
          externalApproverId
          internalApprover {
            user {
              name
            }
          }
          externalApprover {
            name
          }
        }
        itemHistory
      }
      projectEqcTypeStage {
        projectEqcTypeStageApprovals {
          levelName
          levelNumber
          internalApproverId
          externalApproverId
          internalApprover {
            user {
              name
            }
          }
          externalApprover {
            name
          }
        }
      }
      itemHistory
    }
  }
`;

export const GET_URLS = gql`
  query getSignedPutUrls($data: [SignedUrlDataInput]) {
    getSignedPutUrls(data: $data) {
      signedUrls
      keys
    }
  }
`;

export const GET_URL_BY_KEY = gql`
  query getImagesSignedUrls($data: ImageSignedUrlDataInput!) {
    getImagesSignedUrls(data: $data)
  }
`;

export const PROJECT_EQC_TYPE_AGENCY_LIST = gql`
  query projectEqcTypeAgencyList($filter: ProjectAgencyFilter!) {
    projectEqcTypeAgencyList(filter: $filter) {
      total
      data {
        id
        agency {
          name
        }
      }
    }
  }
`;

export const PROJECT_AGENCY_CONTACT_LIST = gql`
  query projectAgencyContactList($filter: ProjectAgencyFilter) {
    projectAgencyContactList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const PROJECT_INSPECTOR_LIST = gql`
  query projectInspectorList($filter: UserFilter!) {
    projectInspectorList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
