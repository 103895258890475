import { Button, Descriptions } from 'antd';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import { Import, InfoIcon } from '../../../../assets/svg';
import {
  AGENCY_TYPE,
  DATETIMEWITHBRACKET,
  GA_EVENT,
  GA_LABEL
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import EllipsisText from '../../../../components/EllipsisText';
import InstructionRenameModal from './InstructionRenameModal';

const InstructionSummary = ({ instructionData, hideTitle = false }) => {
  const [showRenameModal, setShowRenameModal] = useState(false);
  const { getToken, getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const renderDownloadButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/report/contact-instruction/${instructionData?.id}?access_token=Bearer ${token}`;
    return (
      token && (
        <Button
          shape="round"
          icon={<Import />}
          className="report-button b-0 b-0 width-percent-100 justify-center mt-24"
          target="_blank"
          href={url}
          onClick={() => {
            Event(GA_EVENT.DOWNLOAD_ISSUE_REPORT, {
              label: GA_LABEL.DOWNLOAD_ISSUE_REPORT,
              // eslint-disable-next-line no-undef
              pathname: window?.location?.href,
              instruction_id: instructionData?.id
            });
          }}
        >
          Download Report
        </Button>
      )
    );
  };

  const handleRenameModal = () => {
    setShowRenameModal(true);
    Event(GA_EVENT.VIEW_PROJECT_INSTRUCTION_RENAME_MODAL, {
      label: GA_LABEL.VIEW_PROJECT_INSTRUCTION_RENAME_MODAL,
      // eslint-disable-next-line no-undef
      pathname: window?.location?.href,
      project_id: instructionData?.projectId,
      project_instruction_id: instructionData?.id,
      user_id: currentUser?.id,
      user_name: currentUser?.name,
      tenant_id: currentUser?.tenantUser?.tenant?.id,
      tenant_name: currentUser?.tenantUser?.tenant?.organizationName
    });
  };

  return (
    <div className="instruction-tab-details height-percent-100">
      <h2 className="mb-10" hidden={hideTitle}>
        Details
      </h2>
      <div className="d-flex justify-between flex-vertical height-percent-100">
        <Descriptions layout="vertical" colon={false} column={2}>
          <Descriptions.Item label="Organization">
            <div>{instructionData?.tenant?.organizationName || '-'}</div>
          </Descriptions.Item>
          <Descriptions.Item label="Agency">
            {instructionData?.projectAgency?.agency?.type !==
              AGENCY_TYPE.LOCAL && (
              <EllipsisText
                text={instructionData?.projectAgency?.agency?.name || '-'}
              />
            )}
            {instructionData?.projectAgency?.agency?.type ===
              AGENCY_TYPE.LOCAL && (
              <div>
                Phone Contact
                {instructionData?.instructionAgencyContacts?.length > 0 && (
                  <div>
                    <EllipsisText
                      text={`${instructionData?.instructionAgencyContacts?.[0]?.contact?.name} - ${instructionData?.instructionAgencyContacts?.[0]?.contact?.phoneNo}`}
                    />
                  </div>
                )}
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <div className="d-flex">
                Name
                {instructionData?.instructionRenameLogs?.length > 0 && (
                  <InfoIcon
                    width={16}
                    className="pointer ml-5"
                    onClick={handleRenameModal}
                  />
                )}
              </div>
            }
          >
            <EllipsisText text={instructionData?.name || '-'} />
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            <div>{instructionData?.type || '-'}</div>
          </Descriptions.Item>
          <Descriptions.Item label="Tags">
            <div>
              {instructionData?.tags?.length > 0
                ? instructionData?.tags?.map((tag) => <div>{`#${tag}`}</div>)
                : '-'}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Raised By">
            <div>
              <EllipsisText text={instructionData?.creator?.name || '-'} />
              <div>
                {instructionData?.createdOn &&
                  moment(instructionData?.createdOn).format(
                    DATETIMEWITHBRACKET
                  )}
              </div>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Deadline">
            <div>
              {instructionData?.reminderDate
                ? moment(instructionData?.reminderDate).format(
                    DATETIMEWITHBRACKET
                  )
                : '-'}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Revision">
            <div>
              {instructionData?.instructionIssueHistories?.[0]?.revision || '-'}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Location">
            {instructionData?.instructionIssueHistories?.[0]?.latitude &&
            instructionData?.instructionIssueHistories?.[0]?.longitude ? (
              <a
                href={`https://maps.google.com/?q=${instructionData?.instructionIssueHistories?.[0]?.latitude},${instructionData?.instructionIssueHistories?.[0]?.longitude}`}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  Event(GA_EVENT.VIEW_LOCATION, {
                    label: GA_LABEL.VIEW_LOCATION,
                    // eslint-disable-next-line no-undef
                    pathname: window?.location?.href
                  });
                }}
              >
                View Location
              </a>
            ) : (
              '-'
            )}
          </Descriptions.Item>
        </Descriptions>
        {renderDownloadButton()}
      </div>
      {showRenameModal && (
        <InstructionRenameModal
          showModal={showRenameModal}
          setShowModal={setShowRenameModal}
          data={instructionData?.instructionRenameLogs}
        />
      )}
    </div>
  );
};

export default InstructionSummary;
